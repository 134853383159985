import * as BOOTSTRAP from './bootstrap'
// import * as GSAP from './gsap'

import Favicon from './../img/favicon.png'
import Mail from './../img/icon_mail.svg'
import Tel from './../img/icon_tel.svg'

// GB Trasporti
// import Logo from './../img/logo_gb_trasporti.svg'
// import GBTrasporti231PDF from './../file/231_GB_trasporti_web_version.pdf'
// import GBTrasportiManualePDF from './../file/Manuale_portale_GB_Trasporti.pdf'

// Kamila
// import Logo from './../img/logo_kamila.svg'
// import KamilaManualePDF from './../file/Manuale_portale_Kamila.pdf'

// Logistica Italtrans
import Logo from './../img/logo_logistica_italtrans.svg'
// import LogisticaItaltransManualePDF from './../file/Manuale_portale_Logistica_Italtrans.pdf'





